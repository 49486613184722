var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Informatii despre activitate")])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Data inceput","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Data start',"message":errors[0]}},[_c('b-datetimepicker',{attrs:{"date-parser":_vm.parser,"locale":"ro","timepicker":{ enableSeconds: _vm.enableSeconds, hourFormat: _vm.hourFormat },"horizontal-time-picker":""},on:{"input":_vm.intervalChanged},model:{value:(_vm.volunteerActivity.startDateTime),callback:function ($$v) {_vm.$set(_vm.volunteerActivity, "startDateTime", $$v)},expression:"volunteerActivity.startDateTime"}})],1)]}}])})],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Data sfarsit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Data final',"message":errors[0]}},[_c('b-datetimepicker',{attrs:{"date-parser":_vm.parser,"locale":"ro","timepicker":{ enableSeconds: _vm.enableSeconds, hourFormat: _vm.hourFormat },"horizontal-time-picker":""},on:{"input":_vm.intervalChanged},model:{value:(_vm.volunteerActivity.endDateTime),callback:function ($$v) {_vm.$set(_vm.volunteerActivity, "endDateTime", $$v)},expression:"volunteerActivity.endDateTime"}})],1)]}}])})],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Ore","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Ore',"message":errors[0]}},[_c('b-input',{attrs:{"disabled":true},model:{value:(_vm.volunteerActivity.hours),callback:function ($$v) {_vm.$set(_vm.volunteerActivity, "hours", $$v)},expression:"volunteerActivity.hours"}})],1)]}}])})],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Descriere"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":'Descriere',"message":errors[0]}},[_c('b-input',{model:{value:(_vm.volunteerActivity.description),callback:function ($$v) {_vm.$set(_vm.volunteerActivity, "description", $$v)},expression:"volunteerActivity.description"}})],1)]}}])})],1)])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"grouped":""}},[_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"submit"},on:{"click":_vm.addVolunteerActivity}},[_vm._v("Salvare")])],1),_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-danger is-outlined"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Anulare")])],1)])],1)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }