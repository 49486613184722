<template>
  <div>
    <ValidationObserver ref="form">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">Informatii despre activitate</p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    name="Data inceput"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :label="'Data start'"
                      :message="errors[0]"
                    >
                      <b-datetimepicker
                        :date-parser="parser"
                        v-model="volunteerActivity.startDateTime"
                        locale="ro"
                        :timepicker="{ enableSeconds, hourFormat }"
                        horizontal-time-picker
                        @input="intervalChanged"
                      >
                      </b-datetimepicker>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    name="Data sfarsit"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :label="'Data final'"
                      :message="errors[0]"
                    >
                      <b-datetimepicker
                        :date-parser="parser"
                        v-model="volunteerActivity.endDateTime"
                        locale="ro"
                        :timepicker="{ enableSeconds, hourFormat }"
                        horizontal-time-picker
                        @input="intervalChanged"
                      >
                      </b-datetimepicker>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    name="Ore"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :label="'Ore'"
                      :message="errors[0]"
                    >
                      <b-input
                        v-model="volunteerActivity.hours"
                        :disabled="true"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <ValidationProvider name="Descriere" v-slot="{ errors }">
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :label="'Descriere'"
                      :message="errors[0]"
                    >
                      <b-input v-model="volunteerActivity.description" />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field grouped>
                  <div class="control">
                    <b-button
                      type="is-primary"
                      native-type="submit"
                      @click="addVolunteerActivity"
                      >Salvare</b-button
                    >
                  </div>
                  <div class="control">
                    <b-button type="is-danger is-outlined" @click="cancel()"
                      >Anulare</b-button
                    >
                  </div>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { rawObject } from "@/helpers/utils";
import Volunteering from "../../../services/volunteering.service";
import { pathOr } from "ramda";
import moment from "moment";

export default {
  name: "VolunteerActivityAdd",
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    const volunteerId = parseInt(
      pathOr(null, ["params", "volunteerId"], this?.$route)
    );
    this.volunteerId = volunteerId;
    if (id) {
      this.editMode = true;
      this.getVolunteerActivity(id);
    } else {
      this.editMode = false;
    }
  },
  data() {
    return {
      volunteerActivity: {
        hours: 0,
        startDateTime: null,
        endDateTime: null,
      },
      volunteerActivityId: 0,
      editMode: false,
      enableSeconds: false,
      hourFormat: "24",
      volunteerId: 0,
    };
  },
  methods: {
    intervalChanged() {
      var start = moment(this.volunteerActivity.startDateTime);
      var end = moment(this.volunteerActivity.endDateTime);
      var duration = moment.duration(end.diff(start));
      var hours = duration.asHours();
      this.volunteerActivity.hours = hours;
    },
    parser(d) {
      return new Date(Date.parse(d));
    },
    addVolunteerActivity() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.volunteerActivity.volunteerId = this.volunteerId;
        this.volunteerActivity.activityTypeId = 1;
        if (this.editMode) {
          this.$emit(
            "handleEditVolunteerActivity",
            rawObject(this.volunteerActivity)
          );
        } else {
          this.$emit(
            "handleAddVolunteerActivity",
            rawObject(this.volunteerActivity)
          );
        }
      });
    },
    async getVolunteerActivity(id) {
      const { getVolunteerActivity } = Volunteering;
      const response = await getVolunteerActivity(id);
      const { data } = response;
      this.volunteerActivity = data;
      if (this.volunteerActivity.startDateTime) {
        this.volunteerActivity.startDateTime = new Date(
          this.volunteerActivity.startDateTime
        );
      }
      if (this.volunteerActivity.endDateTime) {
        this.volunteerActivity.endDateTime = new Date(
          this.volunteerActivity.endDateTime
        );
      }
    },
    cancel() {
      this.$router.push({ path: `/volunteers/${this.volunteerId}/activities` });
    },
  },
};
</script>
