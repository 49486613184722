<template>
  <div>
    <title-bar :title-stack="titleStack" :volunteerId="volunteerId" />
    <section class="section is-main-section">
      <card-component
        :title="formCardTitle"
        icon="file-edit"
        class="tile is-child"
      >
        <volunteer-activity-add
          @handleAddVolunteerActivity="handleAddVolunteerActivity"
          @handleEditVolunteerActivity="handleEditVolunteerActivity"
        />
      </card-component>
    </section>
  </div>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import CardComponent from "@/components/CardComponent";
import { pathOr } from "ramda";
import Volunteering from "../services/volunteering.service";
import VolunteerActivityAdd from "../components/volunteering/volunteers/volunteerActivity-add.vue";

export default {
  components: { TitleBar, CardComponent, VolunteerActivityAdd },
  name: "VolunteerActiviy",
  data() {
    return {
      volunteer: {},
      volunteerId: 0,
      editMode: false,
    };
  },
  computed: {
    titleStack() {
      if (this.editMode) {
        return [
          "Registru Voluntariat",
          `${this.volunteer.firstName} ${this.volunteer.lastName}`,
          "Editare activitate",
        ];
      } else {
        return [
          "Registru Voluntariat",
          `${this.volunteer.firstName} ${this.volunteer.lastName}`,
          "Adaugare activitate",
        ];
      }
    },
    formCardTitle() {
      if (this.editMode) {
        return "Editare activitate";
      } else {
        return "Adaugare activitate";
      }
    },
  },
  methods: {
    async handleAddVolunteerActivity(volunteerActivity) {
      const { addVolunteerActivity } = Volunteering;
      const response = await addVolunteerActivity(volunteerActivity);
      const { data, status } = response;
      if (status == "200") {
        this.$buefy.snackbar.open({
          message: "Inregistrea a fost adaugata.",
          queue: false,
        });
        this.$router.push({
          path: `/Volunteering`,
        });
      }
    },
    async handleEditVolunteerActivity(volunteerActivity) {
      const { editVolunteerActivity } = Volunteering;
      const response = await editVolunteerActivity(volunteerActivity);
      const { data, status } = response;
      if (status == "200") {
        this.$buefy.snackbar.open({
          message: "Inregistrea a fost modificata.",
          queue: false,
        });
        this.$router.push({
          path: `/Volunteering`,
        });
      }
    },
    async getVolunteer(id) {
      const { getVolunteer } = Volunteering;
      const response = await getVolunteer(id);
      const { data } = response;
      this.volunteer = data;
    },
  },
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    const volunteerId = parseInt(
      pathOr(null, ["params", "volunteerId"], this?.$route)
    );

    this.volunteerId = volunteerId;
    this.getVolunteer(this.volunteerId);

    if (id) {
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  },
};
</script>

<style></style>
